import Image from "next/image";

export default function Home() {
  return (
    <div style={{ display: "grid", height: "100vh", padding: "1em" }}>
      <Image src="/clients-online.svg" layout="fill" alt="clients-online" priority={true} />
    </div>
  );
}

Home.withoutLayout = true;
